//console.log("Vite ⚡️ Rails");

// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'

// Entry point for the build script in your package.json
import "@hotwired/turbo-rails";
// import "./controllers";
import toastr from "toastr";
import toastrOptions from "../shared/toastr-config";
toastr.options = toastrOptions;

document.addEventListener("DOMContentLoaded", function (event) {
  if (typeof window.flashMessages !== "undefined") {
    window.flashMessages.forEach(function (item) {
      const messageType = () => {
        switch (item.type) {
          case "alert":
            return "error";
          case "notice":
            return "success";
          default:
            return item.type;
        }
      };
      toastr[messageType()](item.message);
    });
  }
});
